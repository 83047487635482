.age-verification-dialog {padding: 1rem 1rem 4rem 1rem; display: flex; flex-direction: column; align-items: center; justify-content: center;}

.age-verification-dialog .age-verifiication-icon {margin: 2rem 0; text-align: center;} 
.age-verification-dialog .age-verifiication-icon img {width: 100%;}

.age-verification-dialog header { text-align: center; margin-top: 3rem; margin-bottom: 4rem;}
.age-verification-dialog header .icon {overflow: hidden; margin-bottom: 2rem;}
.age-verification-dialog header h5 {margin-bottom: .5rem; font-size: 1.5rem; font-weight: bold;}
.age-verification-dialog header p {color: $gray2; font-size: 1rem; line-height: 1.25; text-align: center;}

.age-verification-dialog .age-verification-input {display: flex; line-height: 1.25;}
.age-verification-dialog .age-verification-input__label {margin-left: 1rem;}
