.payment-methods-scene {display: flex; flex-direction: column; align-items: center; justify-content: center; padding-top: 1rem; padding-bottom: 3rem;}
.payment-methods-scene .header-icon {margin: 2rem 0;} 
.payment-methods-scene .header-icon img {width: 100%;}
.payment-methods-scene h5 { margin-bottom: 1rem; width: 100%; text-align: center;}
.payment-methods-scene .add-payment-method { margin: 1rem; width: 100%;}
.payment-methods-scene .empty-message { color: $gray4 }

.payment-methods-scene .cta-container { text-align: center; }

@media (max-width: $breakpoint-mobile) {
  .payment-methods-scene .cta-container { position: fixed; bottom: 0; left: 0; width: 100%; height: 50px; border-radius: 0; margin: 0;}
}