.order-details-scene {padding-top: 0;}

.order-details-scene .kl-spinner { margin-top: 1rem;}

.order-details-scene section header { padding: 1rem 1rem .75rem 1rem; font-weight: 400; color: $gray3;}
.order-details-scene section div.content {margin-bottom: 1rem; padding: 1rem; background-color: white; box-shadow: $box-shadow2;}

// Timeline
.order-details-scene .order-status-timeline {margin: 1rem 0;}
.order-details-scene .order-cancelled-callout {margin-bottom: 16px;}

// Order number
.order-details-scene section.order-number header h2 { display: flex; justify-content: space-between;}
.order-details-scene section.order-number .content { display: flex; align-items: center; justify-content: space-between;}
.order-details-scene section.order-number .content .number {font-weight: 700;}

// Store feedback
.order-details-scene .store-feedback .content {padding: 1.5rem 2rem;}
.order-details-scene .store-feedback .content h3 {text-align: center; line-height: 1.5; font-size: 1.125rem; color: $gray3; margin-bottom: 1rem;}
.order-details-scene .store-feedback .content h3 b {color: $black1; font-weight: 700;}
.order-details-scene .store-feedback .content .rating-stars-container {background-color: $light-gray5; padding: 1.25rem 1.5rem;}

.order-details-scene .store-feedback .feedback-notes {display:flex; flex-direction:column; background-color: white}
.order-details-scene .store-feedback .feedback-notes .kl-spinner{margin:auto}
.order-details-scene .store-feedback .feedback-notes .header-feedback-notes {display: flex;justify-content: center; padding-bottom: 1rem; align-items: center}
.order-details-scene .store-feedback .feedback-notes .feedback-notes-submit {align-self: center; padding:10px; color: $light-blue2; font-size: 0.9rem;font-weight: 700}
.order-details-scene .store-feedback .feedback-notes .header-feedback-notes .header-title {font-weight: 700;padding-right: 15px;font-size: 1rem;}
.order-details-scene .store-feedback .feedback-notes .kl-input.feedback-notes-input {margin-bottom: .5rem;}
.order-details-scene .store-feedback .feedback-notes .feedback-submitted-message {text-align: center; font-size: 1rem; color: $gray3; margin-bottom: 1rem; line-height: 1.15;}

// Products feedback
.order-details-scene section.products-feedback .content {padding: 1.5rem 1rem;}
.order-details-scene section.products-feedback .content ul.product-list {display: grid; row-gap: 1.5rem;}
.order-details-scene section.products-feedback .content ul.product-list li { display: flex; justify-content: space-between; align-items: center;}
.order-details-scene section.products-feedback .content ul.product-list li .product-details p {color: $gray3; margin-top: .25rem;}
.order-details-scene section.products-feedback .content ul.product-list h3 b {color: $black1; font-weight: 700;}
.order-details-scene section.products-feedback .content ul.product-list .like-buttons {display: grid; column-gap: 1rem; grid-template-columns: 1fr 1fr;}

// FAQ
.order-details-scene section.faq .content { display: flex; align-items: center;}
.order-details-scene section.faq .content .help-icon {color: $gray3; margin-right: .5rem;}
.order-details-scene section.faq .content .chevron {color: $gray3; margin-left: auto;}

// Order details
.order-details-scene section.order-details .content .store-line { display: flex; align-items: center; border-bottom: 1px solid $light-gray4; padding-bottom: 1rem;}
.order-details-scene section.order-details .content .store-line figure {height: 2.5rem; width: 2.5rem; margin-right: 1rem;}
.order-details-scene section.order-details .content .store-line img { border-radius: .25rem; width: 100%; height: 100%; object-fit: cover; object-position: center;}
.order-details-scene section.order-details .content .store-line h3.store-name {margin-bottom: .25rem;}
.order-details-scene section.order-details .content .store-line h4.location-name {font-size: 1.125rem; font-weight: 700;}

.order-details-scene section.order-details .content ul.product-lines li { align-items: center; display: flex; flex-direction: row; padding: .5rem 0;}
.order-details-scene section.order-details .content ul.product-lines li:first-child { padding-top: 1rem;}
.order-details-scene section.order-details .content ul.product-lines li:last-child {padding-bottom: 0;}
.order-details-scene section.order-details .content ul.product-lines li:last-child .items {border-bottom: none;}
.order-details-scene section.order-details .content ul.product-lines li .quantity { align-self: flex-start; margin-right: 1.5rem;}
.order-details-scene section.order-details .content ul.product-lines li .items { flex: 1; border-bottom: 1px solid $light-gray4; }
.order-details-scene section.order-details .content ul.product-lines li .items .item-line { margin-bottom: .5rem; align-items: center; display: flex; flex-direction: row; justify-content: space-between;}
.order-details-scene section.order-details .content ul.product-lines li .items .modifier-group { flex: 1; color: $gray2 }
.order-details-scene section.order-details .content ul.product-lines li .items .item-line.modifier { color: $gray2; font-size: .85rem; }
.order-details-scene section.order-details .content ul.product-lines li .items .item-line:last-child { padding-bottom: 1rem; margin-bottom: 0;}

.order-details-scene section.order-details .content ul.product-lines li p.customer-notes {margin-bottom: 1rem; font-size: 0.875rem; color: $gray2; line-height: 1.15; background: $light-gray4; padding: .5rem; border-radius: .25rem;}

.order-details-scene section.order-details .content ul.non-product-lines li { text-align: right; padding: 1rem 0; border-bottom: 1px solid $light-gray4;}
.order-details-scene section.order-details .content ul.non-product-lines li:first-child {border-top: 1px solid $light-gray4;}
.order-details-scene section.order-details .content ul.non-product-lines li:last-child {border-bottom: none;}
.order-details-scene section.order-details .content ul.non-product-lines li .title {color: $gray2; margin-right: 1rem; text-align: left;}
.order-details-scene section.order-details .content ul.non-product-lines li .amount { display: inline-block; min-width: 4.5rem;}

.order-details-scene section.order-details .content .total-line { text-align: right; padding: 1rem 0 .5rem 0; border-top: 1px solid $light-gray4; font-weight: 700;}
.order-details-scene section.order-details .content .total-line .title { margin-right: 1rem;}
.order-details-scene section.order-details .content .total-line .amount { display: inline-block; min-width: 4.5rem;}

// Live Nation order information
.order-details-scene .order-information .location-attribute {margin-bottom: .875rem;}
.order-details-scene .order-information .location-attribute:last-child {margin-bottom: 0;}
.order-details-scene .order-information .location-attribute-name {font-size: 0.875rem; color: $gray3; margin-bottom: .25rem;}


// Pickup info
.order-details-scene section.pickup-info .content .pickup-info {margin-top: .5rem; color: $gray3;}
.order-details-scene section.pickup-info .content .pickup-at {margin-top: .5rem;}

// Payment method
.order-details-scene section.payment-method .content { display: flex; align-items: center;}
.order-details-scene section.payment-method .content .wallet-icon { margin-right: 1rem}
.order-details-scene section.payment-method .content .card-masked-number { font-size: 1rem;}

// Room charge
.order-details-scene section.room-charge .content { display: grid; grid-template-columns: repeat(2, 1fr);}
.order-details-scene section.room-charge .content .line { margin-bottom: .5rem;}
.order-details-scene section.room-charge .content .line > .label { font-size: 0.875rem; color: $gray3; margin-bottom: .25rem}
.order-details-scene section.room-charge .content .line > .value { margin-bottom: .5rem;}

// Cancel order
.order-details-scene .cancel-section {margin: 1rem 0; display: flex; justify-content: center;}

@media (min-width: $breakpoint-mobile) {
  .order-details-scene .scene-content {width: 50%; margin: 0 auto; padding: 0;}
  .order-details-scene section .content { border-radius: .5rem;}
}
