.order-status-timeline {display: grid; grid-template-columns: 1fr 10% 1fr 10% 1fr 10% 1fr; padding: 0 1rem;}
.order-status-timeline .order-status-step {display: flex; flex-direction: column; align-items: center;}
.order-status-timeline .order-status-step.active .step-icon {background-color: #11C89A;}
.order-status-timeline .order-status-step.active .step-name {color: #11C89A;}
.order-status-timeline .order-status-step .step-icon {display: flex; align-items: center; justify-content: center; height: 2rem; width: 2rem; margin-bottom:.5rem; background-color: $gray4; border-radius: 100%; padding: 0;}
.order-status-timeline .order-status-step .step-name {color: $gray4; font-size: 0.875rem; white-space: nowrap;}
.order-status-timeline .step-trail {margin-top: 1rem; height: 0; border-bottom: 1px dashed $gray3;}
.order-status-timeline .step-trail.active {border-color: #11C89A;}

@media (max-width: $breakpoint-mobile) {
  .order-status-timeline .order-status-step {justify-content: flex-start;}
}