.kl-button {font-size: 1rem; padding: .375rem .75rem; font-weight: 700; border: 1px solid $light-blue2; background-color: inherit; padding: .5rem; background-color: $light-blue2; border-radius: $border-radius1; color: #FFF; cursor: pointer; transition: background-color 50ms linear;}
.kl-button:hover {background-color: mix(black, $light-blue2, 10%); border-color: mix(black, $light-blue2, 10%);}
.kl-button:disabled {background-color: mix(white, $light-blue2, 50%); border-color: mix(white, $light-blue2, 50%);}

// Colors
.kl-button.kl-button-danger {background-color: #EF5251; border-color: #EF5251;}
.kl-button.kl-button-danger:hover {background-color: #DF4E4E;}
.kl-button.kl-button-danger:disabled {background-color: #EF525280;}
.kl-button.kl-button-minimal.kl-button-danger {background-color: transparent; color: #FF0000;}
.kl-button.kl-button-minimal.kl-button-danger:hover {background-color: #FF000046;}

// Sizes
.kl-button.kl-button-sm {padding: .25rem .5rem; font-size: .875rem;}
.kl-button.kl-button-lg {padding: .5rem 1rem; font-size: 1.25rem;}

// Utilities
.kl-button.kl-outlined {border: 1px solid $light-blue2; background-color: white; color: $light-blue2;}
.kl-button.kl-button-minimal {background-color: transparent; color:$light-blue2; border: none;}
.kl-button.kl-button-bold {font-weight: 700;}
.kl-button.kl-fill {width: 100%;}