.room-charge-details-dialog {padding: 1rem 1rem 4rem 1rem; display: flex; flex-direction: column; align-items: center; justify-content: center;}

.room-charge-details-dialog header {margin: 2rem 0;}
.room-charge-details-dialog header h1 {text-align: center; font-size: 1.25rem; line-height: 1.5;}

.room-charge-details-dialog .kl-input-group {width: 100%;}
.room-charge-details-dialog .room-charge-details-from {width: 100%;}
.room-charge-details-dialog .room-charge-details-from .kl-input-group:last-child { margin-bottom: 0 ;}

@media (max-width: $breakpoint-mobile) {
  .room-charge-details-dialog .pickup-details-cta {position: fixed; bottom: 0; left: 0; width: 100%; height: 50px; border-radius: 0; margin: 0;}
}