// Input
.kl-input {border: 1px solid $gray4; border-radius: $border-radius1; padding: .375rem .75rem; font-size: 1rem; font-weight: 400; line-height: 1.5;  outline: none; width: 100%; background-color: white;}
.kl-input:focus {outline: none;}
.kl-input:focus-visible {outline: none;}
.kl-input:focus-within {outline: none;}
.kl-input.kl-fill {width: 100%;}

// Colors
.kl-phone-input.kl-input-danger {border-color: #EF5251;} 

// Input Group
.kl-input-group {margin-bottom: 1rem;}
.kl-input-group .kl-input-helper {font-size: 12px; margin-top: 4px;}
.kl-input-group .kl-input-label { display: block; margin-bottom: .25rem;}

// Colors
.kl-input-group.kl-input-group-danger :is(.kl-input, .kl-phone-input) {border-color: #EF5251;}
.kl-input-group.kl-input-group-danger .kl-input-helper {color: #EF5251;}
