.handle {border-radius: 100%; width: 24px; height: 24px; overflow: hidden; display: flex; align-items: center; justify-content: center; cursor: pointer; font-size: 12px; color: $black1; transition: background-color 100ms linear;;}
.handle:hover {background-color: #0000001A;}

nav.nav-bar {position: fixed; left: 0; top: 0; height: $navbar-height; padding: 1rem; width: 100%; box-shadow: $box-shadow1; background-color: #FFF; z-index: 100; display: flex; align-items: center;}
nav.nav-bar .location { display: flex; justify-content: flex-end; flex: 1; align-items: center; }
nav.nav-bar .menu-icon {cursor: pointer; color: $gray3; padding: 5px; transition: background-color 100ms linear; border-radius: 100%;}
nav.nav-bar .menu-icon:hover {background-color: #0000001A;}
nav.nav-bar .location-image {width: 50px; height: 50px; margin-left: 20px; border-radius: $border-radius1; overflow: hidden; flex-shrink: 0;}
nav.nav-bar .location-image img {width: 100%; height: 100%; object-fit: cover; object-position: center;}
nav.nav-bar .location-info {margin-left: 20px; color: $black1; text-align: right;}
nav.nav-bar .location-info .location-store {font-size: 1.15rem;}
nav.nav-bar .location-info .location-name {font-weight: bold; font-size: 1rem; margin-top: .5rem;}
nav.nav-bar .location-address-handle {margin-left: 10px; transform: rotate(0); transition: transform 200ms linear, background-color 100ms linear;}
nav.nav-bar .location-address-handle.showing {transform: rotate(180deg);}

.location-address {position: fixed; width: 100%; top: 0; left: 0; padding: 12px 20px; background-color: #FFF; box-shadow: $box-shadow1; color: $black1; z-index: 99; transition: top 150ms linear; display: flex; align-items: center;}
.location-address.show {top: $navbar-height;}
.location-address a {color: $black1; margin-left: 10px;}

.dynamic-content {padding-top: $navbar-height; width: 100%;}
.dynamic-content.no-scroll {overflow: hidden;}
.dynamic-content .kl-spinner {margin-top: 1rem;}

.navigator {display: flex; flex-direction: column; position: fixed; width: 300px; height: 100%; left: 0; top: 0; z-index: 101; background-color: #FFF; box-shadow: $box-shadow2; transform: translateX(-305px); transition: transform 100ms linear;}
.navigator.open {transform: translateX(0);}
.navigator .close-handle {position: absolute; top: 15px; right: 15px; color: $gray3;}
.navigator .kl-logo {width: 100%; height: 60px; overflow: hidden; padding: 0 80px; flex-shrink: 0;}
.navigator .kl-logo img {width: 100%; height: 100%; object-fit: contain; object-position: center;}
.navigator .menu-list {display: flex; flex-direction: column; flex: 1; cursor: pointer; padding: 0 10px;}
.navigator .menu-list .menu-item {display: flex; align-items: center; padding: 15px 20px; border-bottom: 1px solid #0000001F;}
.navigator .menu-list .menu-item a{ text-decoration: none}
.navigator .menu-list .menu-item:first-of-type {border-top: 1px solid #0000001F;}
.navigator .menu-list .menu-item a{ text-decoration: none}
.navigator .menu-list .menu-item:hover {background-color: #0000001A;}
.navigator .menu-list .menu-item.active {background-color: #0000001A;}
.navigator .menu-list .menu-item.sign-out {display: flex; justify-content: center; border-bottom: none;}
.navigator .menu-list .menu-item.sign-out .item-text {color: $blue1; font-size: 14px; font-weight: 700;}
.navigator .menu-list .menu-item.sign-out .item-icon {color: $blue1; font-size: 14px; font-weight: 700;}
.navigator .menu-list .menu-item .item-image {width: 40px;}
.navigator .menu-list .menu-item .item-image img {width: 40px; height: 40px; object-fit: contain;}
.navigator .menu-list .menu-item .item-icon {width: 40px; text-align: center; color: $gray3;}
.navigator .menu-list .menu-item .item-text {margin-left: 10px; color: $gray3;}
.navigator .menu-list .menu-item .item-handle {margin-left: auto; color: $gray3;}
.navigator .blank-space {flex: 1; width: 100%; border: none; cursor: default;}
.navigator .blank-space + .menu-item {border-top: 1px solid #0000001F;}
.navigator .app-version {font-size: 12px; color: $gray3; text-align: center; padding: 10px 0;}

.store-info-list { display: flex; gap: 1rem; flex-direction: column; padding: .75rem 1.5rem 1.5rem 1.5rem;}
.store-info-list__item {display: flex; align-items: center;}
.store-info-list__item-icon { color: $gray1}
.store-info-list__item-link { margin-left: 1rem; text-decoration: none; color: $black1; line-height: 1.5;}

@media (max-width: $breakpoint-mobile) {
  .navigator {width: 100%; transform: translateX(-100%);}
}