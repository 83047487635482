.scene-location .location-menu {display: flex; align-items: stretch; justify-content: flex-start; flex-wrap: wrap;}
.scene-location .location-menu .menu-categories-list {flex: 1; min-width: 300px;}
.scene-location .location-menu .menu-categories-list .menu-category-item {padding: 15px 40px; cursor: pointer; font-size: 14px; transition: background-color 200ms linear, color 100ms linear; position: relative; outline: none !important;}
.scene-location .location-menu .menu-categories-list .menu-category-item::before {content: ''; position: absolute; left: 20px; top: 50%; transform: translateY(-50%); height: 5px; width: 5px; background-color: #1CC1EB; border-radius: 100%; opacity: 0; transition: all 200ms linear;}
.scene-location .location-menu .menu-categories-list .menu-category-item:hover {position: relative;}
.scene-location .location-menu .menu-categories-list .menu-category-item:hover::before {opacity: 1;}
.scene-location .location-menu .menu-categories-list .menu-category-item.active {background-color: $light-blue1;}
.scene-location .location-menu .menu-categories-list .menu-category-item.active::before {opacity: 1;}
.scene-location .active-discount-expiration {width: 100%; display: flex; align-items: center; justify-content: center; padding: 0 5px; font-size: .8rem; font-weight: 700; z-index: 99; height: 45px;}
.scene-location .active-discount-expiration + .location-menu .location-products .category-title .category-waypoint-top {top: calc(-#{$navbar-height} - 90px); height: 1px; width: 1px;}

.scene-location .location-menu .location-products {flex: 5; display: flex; flex-wrap: wrap; align-items: stretch; justify-content: stretch; padding-bottom: 50px;}
.scene-location .location-menu .location-products .category-title {width: 100%; margin: 0 10px; padding: 1.5rem 1rem .75rem 1rem; font-size: 16px; font-weight: 700; color: $black1; border-radius: $border-radius1; position: relative;}
.scene-location .location-menu .location-products .category-title .category-waypoint-top {position: absolute; left: 0; top: calc(-#{$navbar-height} - 45px);}
.scene-location .location-menu .location-products .category-waypoint-bottom {height: 1px; width: 1px;}
.scene-location .location-menu .location-products h2 { font-weight: 400; color: $gray3;}
.scene-location .location-menu .location-products .product-list { background-color: transparent; width: 100%; display: grid; grid-template-columns: repeat(auto-fill,minmax(20rem, 1fr));}

.product-card {display: flex; align-items: stretch; justify-content: flex-start; flex-direction: row; box-shadow: $box-shadow1; min-height: 75px; margin: 10px; padding: 1rem; border-radius: $border-radius1; cursor: pointer; transform: scale(1); transition: transform 200ms ease; background-color: #FFF;}
.product-card:hover {transform: scale(1.02);}
.product-card .product-details {display: flex; flex-direction: column; align-items: flex-start; flex: 1; margin-left: 1rem;}
.product-card .product-details .product-title {color: $black1; font-weight: 500; font-size: 16px; margin-bottom: 4px; display: flex; align-items: center; width: 100%;}
.product-card .product-details .product-title .discount-name {margin-left: 5px; color: #FFAA66; font-weight: 600;}
.product-card .product-details .product-description {color: $gray3; font-weight: 400; font-size: 0.875rem; line-height: 1.15; overflow: hidden; margin-bottom: .5rem; height: 29px;}
.product-card .product-details .attributes-list {flex-shrink: 0; display: flex; flex-wrap: wrap; margin: 12px 0;}
.product-card .product-details .attributes-list > * {margin: 3px;}
.product-card .product-details .attributes-list .attribute-tag {background-color: $green1; color: #FFF; font-weight: 400; font-size: 14px; padding: 3px 6px; border-radius: $border-radius1;}
.product-card .product-details .product-price {color: $black1; font-weight: 700; font-size: 1rem; margin-top: auto; }
.product-card .product-details .product-price .old-price {text-decoration: line-through;}
.product-card .product-details .product-price .price-with-discount {margin-left: .5rem; color: #FFAA66;}
.product-card .product-image {overflow: hidden; width: 5rem; height: 5rem; border-radius: $border-radius1; flex-shrink: 0;}
.product-card .product-image img {width: 100%; height: 100%; object-fit: cover; object-position: center;}
.product-card .product-image .stock-image {width: 100%; height: 100%; background-color: $light-gray3; display: flex; align-items: center; justify-content: center; color: $gray3;}

.scene-location .open-cart-button {position: fixed; bottom: 30px; right: 30px; display: flex; align-items: center; justify-content: center;}
.scene-location .open-cart-button .kl-icon {margin-right: 8px;}

.scene-location .cta-container { position: absolute; bottom: 2rem; width: 100%; display: flex; justify-content: center;}

.product-price .price-container {display: flex; flex-direction: row; align-items:center;}

@media (max-width: $breakpoint-mobile) {
  .scene-location .active-discount-expiration {display: flex; position: fixed; top: $navbar-height; background-color: #EDF8FF;}
  .scene-location .active-discount-expiration + .location-menu {padding-top: 90px;}
  .scene-location .active-discount-expiration + .location-menu .menu-categories-list {top: calc(#{$navbar-height} + 45px)}
  .scene-location .location-menu {position: relative; flex-direction: column; padding: 2rem 0;}
  .scene-location .location-menu .menu-categories-list {display: flex; align-items: flex-end; position: fixed; top: $navbar-height; flex-direction: row; overflow: auto; margin: 0; z-index: 99; width: 100%; background-color: #FAFBFE; height: 45px; display: flex; align-items: center; padding: 0 5px;}
  .scene-location .location-menu .menu-categories-list .menu-category-item {flex-shrink: 0; padding: 15px; margin: 0; border-radius: $border-radius1; -webkit-tap-highlight-color: hsla(192, 84%, 52%, 0.692); position: relative;}
  .scene-location .location-menu .menu-categories-list .menu-category-item + .menu-category-item {margin-left: 10px;}
  .scene-location .location-menu .menu-categories-list .menu-category-item::before {content: none;}
  .scene-location .location-menu .menu-categories-list .menu-category-item::after {content: ''; width: calc(100% - 10px); height: 4px; background-color: transparent; position: absolute; left: 50%; bottom: 0; transform: translateX(-50%);}
  .scene-location .location-menu .menu-categories-list .menu-category-item.active {font-weight: bold; background-color: transparent; border-radius: 0;}
  .scene-location .location-menu .menu-categories-list .menu-category-item.active::after {background-color: $light-blue2;}
  .scene-location .location-menu .location-products {overflow: auto; width: 100%;}
  .scene-location .location-menu .location-products .category-title {margin: 0; border-radius: 0;  }
  .product-card {width: 100%; box-shadow: none; margin: 0; border-radius: 0; border-bottom: 1px solid $light-gray4;}
  .product-card:hover {transform: none;}
  .scene-location .open-cart-button {position: fixed; bottom: 0; left: 0; width: 100%; border-radius: 0; height: 50px;}
  .scene-location .location-menu .location-products .product-list { display: block; background-color: white; width: 100%; box-shadow: $box-shadow2;}

  .scene-location .cta-container { position: fixed; bottom: 0; left: 0; width: 100%; height: 50px; border-radius: 0; margin: 0;}
}
