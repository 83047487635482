.order-list {display: grid;row-gap: .75rem; padding: .75rem; background-color: white; box-shadow: $box-shadow2;}
.order-list__divider {border-bottom: 1px solid $light-gray4;}

.order-list-item {display: grid; place-items: center; grid-template-rows: 4rem; grid-template-columns: 4rem 2fr 1fr auto; cursor: pointer;}
.order-list-item__avatar {width: 100%; object-fit: cover; height: 100%; border-radius: .25rem;}

.order-list-item__primary-details {place-self: center start; padding-left: .75rem; white-space: nowrap; overflow: hidden; max-width: 100%;}
.order-list-item__store-name {font-size: 1.15rem; margin-bottom: .75rem; text-overflow: ellipsis; overflow: hidden;}
.order-list-item__created-at {color: $gray3; font-size: .875rem;}

.order-list-item__secondary-details {place-self: center end; text-align: center;}
.order-list-item__status-badge-container {margin-bottom: .5rem;}
.order-list-item__total {font-size: .875rem;}
.order-list-item__chevron {padding: .5rem; color: $gray4;}
