.location-scene-placeholder {padding-top: 1rem; padding-bottom: 2rem;}
.location-scene-placeholder .category-list-placeholder {padding: 0 1rem; margin-bottom: 1rem; display: flex; flex-direction: row;}
.location-scene-placeholder .category-list-placeholder .category-link-placeholder {margin: 0 1rem;}
.location-scene-placeholder .category-placeholder .category-title-placeholder {padding: 1rem 1rem .5rem 1rem;}
.location-scene-placeholder .category-placeholder .product-list-placeholder {box-shadow: $box-shadow2; margin-bottom: 1rem;}
.location-scene-placeholder .category-placeholder .product-list-placeholder .product-placeholder{width: 100%; box-shadow: none; margin: 0; border-radius: 0; border-bottom: 1px solid $light-gray4; padding: 1rem; background-color: white;}

@media (min-width: $breakpoint-mobile) {
  .location-scene-placeholder {display: grid; grid-template-columns: 270px 1fr; column-gap: 2rem; padding: 1rem;}
  .location-scene-placeholder .category-list-placeholder {flex-direction: column; margin-top: 1rem;}
  .location-scene-placeholder .category-list-placeholder .category-link-placeholder {margin-bottom: 2rem;}
  .location-scene-placeholder .category-placeholder .product-list-placeholder {display: grid; grid-template-columns: repeat(auto-fill,minmax(20rem, 1fr)); column-gap: 1.5rem; row-gap: 1rem;  box-shadow: none;}
  .location-scene-placeholder .category-placeholder .product-list-placeholder .product-placeholder {border-radius: .25rem;box-shadow: $box-shadow1;}
}