.button {border: 1px solid $light-blue2; border-radius: $border-radius1;color: white;cursor: pointer;display: inline-block;font-weight: 700;transition: background-color 85ms linear;display: inline-block;line-height: 1.5;text-align: center;text-decoration: none;vertical-align: middle;-webkit-user-select: none;-moz-user-select: none;user-select: none;padding: .375rem .75rem;font-size: 1rem;display: flex;justify-content: center;align-items: center;}
.button:disabled {cursor: default;}

// Solid
.button--solid {color: white;}

.button--solid.button--primary {background-color: $light-blue2; border-color: $light-blue2;}
.button--solid.button--primary:active:enabled {background-color: mix(black, $light-blue2, 10%); border-color: mix(black, $light-blue2, 10%);}
.button--solid.button--primary:focus {background-color: $light-blue2; border-color: $light-blue2;}
.button--solid.button--primary:disabled {background-color: mix(white, $light-blue2, 50%); border-color: mix(white, $light-blue2, 50%);}

.button--solid.button--danger {background-color: $red1; border-color: $red1;}
.button--solid.button--danger:active:enabled {background-color: mix(black, $red1, 10%); border-color: mix(black, $red1, 10%);}
.button--solid.button--danger:focus {background-color: $red1; border-color: $red1;}
.button--solid.button--danger:disabled {background-color: mix(white, $red1, 50%); border-color: mix(white, $red1, 50%);}

// Outlined
.button--outlined {background-color: white; }

.button--outlined.button--primary {border-color: $light-blue2;color: $light-blue2;}
.button--outlined.button--primary:active:enabled {background-color: mix(white, $light-blue2, 85%);}
.button--outlined.button--primary:focus {background-color: white; border-color: $light-blue2;color: $light-blue2;}
.button--outlined.button--primary:disabled {border-color: mix(white, $light-blue2, 50%);color: mix(white, $light-blue2, 50%);}

// Minimal
.button--minimal {background-color: transparent; border: none;}

.button--minimal.button--primary {color: $light-blue2;}
.button--minimal.button--primary:active:enabled {background-color: mix(white, $light-blue2, 85%);}
.button--minimal.button--primary:focus {color: $light-blue2;}
.button--minimal.button--primary:disabled {color: mix(white, $light-blue2, 50%);}

.button--minimal.button--danger {color: $red1;}
.button--minimal.button--danger:active:enabled {background-color: mix(white, $red1, 85%);}
.button--minimal.button--danger:focus {color: $red1;}
.button--minimal.button--danger:disabled {color: mix(white, $red1, 50%);}

// Sizes
.button--small {font-size: .875rem; padding: .25rem; }
.button--normal {font-size: 1rem;  padding: .375rem;}
.button--large {font-size: 1.25rem; padding: .5rem; }

// Miscellaneous
.button--gradient {background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));}
.button--full-width {width: 100%;}
.button--full-height {height: 100%;}
.button--sharp {border-radius: 0;}
.button--circle {border-radius: 100%;}

// Elements
.button__icon-left {display: flex; align-items: center;}
.button__text {margin: 0 .5rem;}