// Colors

$black1: #2D2D2D;

$gray1: #6b6b6b;
$gray2: #8392A7;
$gray3: #8392A7;
$gray4: #b5beca;
$gray5: #dadee5;

$light-gray1: #d6d6d6;
$light-gray2: #dedede;
$light-gray3: #e6e6e6;
$light-gray4: #efefef;
$light-gray5: #f7f7f7;

$light-blue1: #E8F9FD;
$light-blue2: #1CC1EB;

$blue1: #0069D9;
$blue2: #0B00DA;
$blue3: #D4D2F8;
$blue4: #3830E1;

$green1: #14DCA9;
$green2: #11B98F;

$red1: #EF5251;

// Order status colors
$color-order-status-received: #2A4874;
$color-order-status-received--hover: #223859;
$color-order-status-preparing: #FEA76E;
$color-order-status-preparing--hover: #DA9262;
$color-order-status-ready: #1CC1EB;
$color-order-status-ready--hover: #1593B2;
$color-order-status-delivered: #0FAD85;
$color-order-status-delivered--hover: #139675;
$color-order-status-cancelled-by-customer: #F60357;
$color-order-status-cancelled-by-customer--hover: #D1054D;
$color-order-status-cancelled-by-store: #F60357;
$color-order-status-cancelled-by-store--hover: #D1054D;
$color-order-status-partially-refunded: #F60357;
$color-order-status-partially-refunded--hover: #D1054D;
$color-order-status-refunded: #F60357;
$color-order-status-refunded--hover: #D1054D;

// Box Shadow
$box-shadow1: 0 2px 4px 0 rgba(192, 204, 219, .50); 
$box-shadow2: 0 1px 4px 0 rgba(192, 204, 219, .50); 

// Border Radius
$border-radius1: 4px;


$navbar-height: 75px;

// Breakpoints
$breakpoint-mobile: 960px;
$breakpoint-tablet: 1440px;
