.checkout-success-scene .main-header {background-color: #DEF9FD; text-align: center; position: relative;}
.checkout-success-scene .main-header .icon {height: 10rem;}
.checkout-success-scene .main-header .background { position: absolute; left: 0; padding: .5rem; top: 2rem; width: 100%;}
.checkout-success-scene .main-header h1 { font-size: 1.5rem; font-weight: 700; color: $light-blue2; padding-bottom: 3rem;}
.checkout-success-scene .order-more-button-container {margin-top: -1.5rem; display: flex; justify-content: center; position: relative;}

.checkout-success-scene .kl-spinner {margin-top: 2rem}

.checkout-success-scene section {margin-top: 1rem}
.checkout-success-scene section > header { display: flex; align-items: center; justify-content: space-between; padding: .75rem 1rem;}
.checkout-success-scene section > header h2 { font-weight: 400; color: $gray3;}

@media (max-width: $breakpoint-mobile) {
  .checkout-success-scene .scene-content {width: 100%; margin: 0; padding: 0;}
  .checkout-success-scene .orders-list-title {padding: 8px; margin: 0;}
}
