@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Nunito, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
  }
}

@import './styles/main.scss';
@import 'react-toastify/dist/ReactToastify.css';
@import '@blueprintjs/icons/lib/css/blueprint-icons.css';
@import 'react-phone-number-input/style.css';

.active-category-link {
  @apply tw-font-bold tw-border-blue-500;
}

// Hide scrollbar for Chrome, Safari and Opera
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

// Hide scrollbar for IE, Edge and Firefox
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// Phone input
.PhoneInput .PhoneInputInput {
  @apply tw-flex tw-w-full tw-shadow-sm tw-border-r tw-border-y tw-rounded-r-md tw-border-l-0 tw-border-gray-300 tw-bg-transparent tw-py-2 tw-px-3 tw-text-sm placeholder:tw-text-slate-400 focus:tw-outline-none  disabled:tw-cursor-not-allowed disabled:tw-opacity-50;
}

.PhoneInput .PhoneInputCountry {
  @apply tw-shadow-sm tw-border-l tw-mr-0 tw-border-y tw-rounded-l-md tw-border-r-0 tw-border-gray-300 tw-bg-transparent tw-py-2 tw-px-3 tw-text-sm placeholder:tw-text-slate-400 focus:tw-outline-none focus:tw-ring-blue-500 disabled:tw-cursor-not-allowed disabled:tw-opacity-50;
}

.PhoneInput.PhoneInput--focus {
  @apply tw-ring-blue-500 tw-ring-2 tw-rounded-md;
}

.PhoneInputInput.PhoneInputInput-focus {
  @apply tw-border-none tw-rounded-l-none tw-outline-none;
}

.vi__character--selected {
  position: relative;
}

.vi__character--selected:empty::after {
  content: '';
  width: 1px;
  height: 16px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: blink 1s infinite step-end;

  @apply tw-bg-gray-500;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
