.product-dialog {padding: 0; color: $black1; padding-bottom: 3rem;}

.product-dialog .kl-spinner {margin-top: 2rem;}

.product-dialog .main-header {background-color: white; box-shadow: $box-shadow1; margin-bottom: 1rem;}
.product-dialog .product-image {width: 100%; height: 12rem; overflow: hidden; display: flex;cursor: pointer}
.product-dialog .product-image img {width: 100%; object-fit: cover;}
.product-dialog .product-image img {width: 100%; object-fit: cover;}

.product-dialog section { margin-bottom: 1rem;}
.product-dialog header { padding: .75rem 1rem;}
.product-dialog header h2 { font-weight: 400; color: $gray1;}
.product-dialog header h2 .modifier-group-description { margin-top: .25rem; font-size: .875rem; font-weight: 400; color: $gray3;}

.product-dialog .product-name {font-size: 1.5rem; font-weight: 700; color: $black1; margin-bottom: .25rem; display: flex; align-items: center;}
.product-dialog .product-name .discount-name {font-size: 1rem; color: #FFAA66; margin-left: 1rem;}
.product-dialog .product-details {padding: 1rem;}
.product-dialog .product-details .product-description {font-size: 0.875rem; line-height: 1.15; font-weight: 400; margin-bottom: 10px; color: $gray2; flex: 1;}
.product-dialog .product-details .product-price {font-size: 1rem; font-weight: 700;}
.product-dialog .product-details .product-price .old-price {text-decoration: line-through;}
.product-dialog .product-details .product-price .price-with-discount {margin-left: .5rem; color: #FFAA66}

.product-dialog .modifier-group-section {margin-top: 10px;}
.product-dialog .modifier-group-section .modifier-group-description {padding: 1rem; font-size: 1rem; color: $gray2;}
.product-dialog .modifier-group-section .modifier-group-error {font-size: 14px; font-weight: 400; margin-bottom: 8px;}

.product-dialog .modifier-list {display: flex; flex-direction: column; padding: 1rem; background-color: white; box-shadow: $box-shadow2;}
.product-dialog .modifier-list-item {display: flex; align-items: center; padding: .5rem 0;}
.product-dialog .modifier-list-item:last-child {margin-bottom: 0;}
.product-dialog .modifier-list-item > * {margin-right: 5px;}
.product-dialog .modifier-list-item .modifier-control {}
.product-dialog .modifier-list-item .modifier-label {display: flex; flex-direction: column; align-items: flex-start; cursor: pointer; flex: 1;}
.product-dialog .modifier-list-item .modifier-label .modifier-details {display: flex; align-items: center; width: 100%;}
.product-dialog .modifier-list-item .modifier-label .modifier-details .modifier-name {font-size: 1rem; font-weight: 400; width: 100%; flex: 1;}
.product-dialog .modifier-list-item .modifier-label .modifier-details .modifier-price {font-size: 1rem;}
.product-dialog .modifier-list-item .modifier-label .modifier-details .modifier-price .old-price {text-decoration: line-through;}
.product-dialog .modifier-list-item .modifier-label .modifier-details .modifier-price .price-with-discount {margin-left: .5rem; color: #FFAA66}
.product-dialog .modifier-list-item .modifier-label .modifier-description {font-size: 12px; font-weight: 400; color: $gray2; margin-top: 4px;}

.product-dialog .customer-notes {display: flex; flex-direction: column; padding: 1rem; background-color: white; box-shadow: $box-shadow2;}
.product-dialog .customer-notes-label {display: block; margin-top: 16px;}
.product-dialog .customer-notes-input {width: 100%; outline: none;}

.product-dialog .add-products-section { height: 100%; display: flex; align-items: center; width: 100%; background-color: #FFF; padding: .5rem;}
.product-dialog .add-products-section .product-count {display: flex; align-items: center; justify-content: center; flex: 1;}
.product-dialog .add-products-section .product-count > * + * {margin-left: 8px;}
.product-dialog .add-products-section .product-count .kl-icon {color: $light-blue2; cursor: pointer;}
.product-dialog .add-products-section .product-count .quantity-input {text-align: center; width: 40%; padding: 4px 5px;}
.product-dialog .add-products-section .add-product-cta {flex: 1;}
.product-dialog .age-verification {margin: 20px 0 25px}
