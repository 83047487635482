.scene.scene-events { padding-top: 0; }
.scene-events .scene-content { width: 60%; margin: 0 auto; }
.scene-events .event-list .event-list-title { padding: 1rem; font-size: 1rem; color:$gray1}
.scene-events .event-list .event-list-item { height: 11rem; position: relative; } 
.scene-events .event-list .event-list-item .event-link { height: 100%; display: flex; justify-content: end; gap: 0.5rem; text-decoration: none; color: white; -webkit-justify-content: flex-end; }
.scene-events .event-list .event-list-item .event-link.event-link--centered { justify-content: center; -webkit-justify-content: center; }
.scene-events .event-list .event-list-item .event-cover-image { position: absolute; object-fit: cover; height: 100%; width: 100%; }
.scene-events .event-list .event-list-item .event-info { padding: 0.5rem; z-index: 10; display: flex; gap: 0.5rem; flex-direction: column; align-items: end; align-self: end; -webkit-align-self: flex-end; -webkit-align-items: flex-end; }
.scene-events .event-list .event-list-item .event-info.event-info--centered { align-items: center; align-self: center; -webkit-align-self: center; -webkit-align-items: center; }
.scene-events .event-list .event-list-item .event-title { background-color: $black1; padding: 0.5rem; font-size: 1rem; }
.scene-events .event-list .event-list-item .event-date { background-color: $black1; padding: 0.5rem; font-size: 0.875rem; }

@media (max-width: $breakpoint-mobile) { 
  .scene-events .scene-content { width: 100%; margin: 0; padding-bottom: 2rem; } 
} 