/**
This is an override for react-toastify
https://github.com/fkhadra/react-toastify
**/

.Toastify__toast-container {padding: 1rem;}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {}
.Toastify__toast-container--top-center {}
.Toastify__toast-container--top-right {}
.Toastify__toast-container--bottom-left {}
.Toastify__toast-container--bottom-center {}
.Toastify__toast-container--bottom-right {}

/** Classes for the displayed toast **/
.Toastify__toast {border-radius: .25rem; min-height: 3rem;}
.Toastify__toast--rtl {}
.Toastify__toast--dark {}
.Toastify__toast--default {}
.Toastify__toast--info {}
.Toastify__toast--success {background-color: #11C89A;}
.Toastify__toast--warning {}
.Toastify__toast--error {}
.Toastify__toast-body {}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {}
.Toastify__close-button--default {}
.Toastify__close-button > svg {}
.Toastify__close-button:hover, .Toastify__close-button:focus {}

/** Classes for the progress bar **/
.Toastify__progress-bar {}
.Toastify__progress-bar--animated {}
.Toastify__progress-bar--controlled {}
.Toastify__progress-bar--rtl {}
.Toastify__progress-bar--default {}
.Toastify__progress-bar--dark {}
