// .checkout-scene {padding-top: .5rem; padding-bottom: 4rem;}
.checkout-scene header {display: flex; align-items: center; justify-content: space-between; padding: .75rem 1rem;}
.checkout-scene .checkout-section {margin-bottom: 1rem;}

// Order items and charges
.checkout-scene .order-summary {background-color: white; padding: 1rem; box-shadow: $box-shadow2;}
.checkout-scene .order-summary .product-item {align-items: center; display: flex; flex-direction: row; padding: .5rem 0;}
.checkout-scene .order-summary .product-item .quantity {align-self: flex-start; margin-right: 1.5rem;}
.checkout-scene .order-summary .product-item .items {flex: 1; border-bottom: 1px solid $light-gray4; }
.checkout-scene .order-summary .product-item .items .item-line {margin-bottom: .75rem; align-items: center; display: flex; flex-direction: row; justify-content: space-between;}
.checkout-scene .order-summary .product-item .items .item-line .old-price {text-decoration: line-through;}
.checkout-scene .order-summary .product-item .items .item-line .discount-price {margin-left: .5rem;}
.checkout-scene .order-summary .product-item .items .item-line.modifier {color: $gray2; font-size: .85rem; }
.checkout-scene .order-summary .product-item .items .item-line:last-child {padding-bottom: 1rem; margin-bottom: 0;}
.checkout-scene .order-summary .product-item .items .modifier-group {flex: 1; color: $gray2 }
.checkout-scene .order-summary .product-item .remove-item {margin-top: -0.5rem; display: flex; padding: .25rem; align-self: flex-start; margin-right: 1rem;}
.checkout-scene .order-summary .product-item p.customer-notes {font-style: italic; line-height: 1.15; margin-bottom: 1rem; font-size: 0.875rem; color: $gray3; background: $light-gray4; padding: .5rem; border-radius: .25rem;}

// Subtotal
.checkout-scene .order-summary .products-subtotal {text-align: right; padding: .5rem 0; padding-bottom: 1rem; border-bottom: 1px solid $light-gray4;}
.checkout-scene .order-summary .products-subtotal .label {text-align: right; color: $gray1; margin-right: 1.5rem;}

// Charges and fees
.checkout-scene .order-summary .charges-line {display: flex; align-items: center; justify-content: space-between; margin: 1rem 0; padding-bottom: 1rem; border-bottom: 1px solid $light-gray4;}
.checkout-scene .order-summary .charges-line .label {display: flex; align-items: center;}
.checkout-scene .order-summary .charges-line .label p {margin-right: .5rem;}

// Tip details and tip selector
.checkout-scene .order-summary .tips {margin: 1rem 0; border-bottom: 1px solid $light-gray4;}
.checkout-scene .order-summary .tips .tip-details {display: flex; align-items: center; justify-content: space-between; margin-bottom: 1rem; }
.checkout-scene .order-summary .tips .tip-details .label {display: flex; align-items: center;}
.checkout-scene .order-summary .tips .tip-details .kl-icon {margin-left: .5rem; color: $gray3;}

.checkout-scene .order-summary .tips .tip-selector {display: flex; justify-content: center; flex-direction: column; transition: height 150ms ease-in-out; background-color: $light-gray5; padding: 0 1rem; border-radius: .25rem; height: 0; overflow: hidden;}
.checkout-scene .order-summary .tips .tip-selector.visible {height: 7rem; }
.checkout-scene .order-summary .tips .tip-selector .options {display: flex; justify-content: space-between; align-items: center; margin-bottom: .5rem; }
.checkout-scene .order-summary .tips .tip-selector .input {display: flex; justify-content: space-between; align-items: center; }

// Total
.checkout-scene .order-summary .total {display: flex; align-items: center; justify-content: space-between; color: $gray1; font-weight: 800; text-align: right;}
.checkout-scene .order-summary .total .label {text-align: right;  margin-right: 1.5rem;}

// Pikcup details
.checkout-scene .pickup-details {padding: 1rem; background-color: white; box-shadow: $box-shadow2; display: flex; justify-content: space-between; align-items: center;}
.checkout-scene .pickup-details .pickup-info {font-size: 0.875rem; color: $gray3; margin-top: .25rem; line-height: 1.15rem;}
.checkout-scene .pickup-details .pickup-at {margin-top: .5rem; font-size: 0.875rem;}

// Coupon
.checkout-scene .coupon-code {padding: 1rem; background-color: white; box-shadow: $box-shadow2; display: flex; justify-content: space-between; align-items: center;}

// Payment method
.checkout-scene .payment-method {padding: 1rem; background-color: white; box-shadow: $box-shadow2; display: flex; justify-content: space-between; align-items: center;}
.checkout-scene .payment-method .card-brand-icon {margin-right: 1rem;}
.checkout-scene .payment-method .card-details {display: flex; align-items: center;}
.checkout-scene .payment-method .card-details .card-masked-number {font-size: 1rem;}

// Room charge details
.checkout-scene .room-charge-details {padding: 1rem; background-color: white; box-shadow: $box-shadow2; display: flex; justify-content: space-between; align-items: center;}
.checkout-scene .room-charge-details .line {margin-bottom: .5rem;}
.checkout-scene .room-charge-details .line > .label {font-size: 0.875rem; color: $gray4; margin-bottom: .25rem}
.checkout-scene .room-charge-details .line > .value {margin-bottom: .5rem;}

// Location details
.checkout-scene .location-details {padding: 1rem; background-color: white; box-shadow: $box-shadow2; display: flex; justify-content: space-between; align-items: center;}
.checkout-scene .location-details .name {font-size: 0.875rem; color: $gray3; margin-top: .5rem;}

// Live Nation order information
.checkout-scene .order-information {padding: 1rem; background-color: white; box-shadow: $box-shadow2; display: flex; justify-content: space-between; align-items: center;}
.checkout-scene .order-information .location-attribute {margin-bottom: .875rem;}
.checkout-scene .order-information .location-attribute-name {font-size: 0.875rem; color: $gray3; margin-bottom: .25rem;}

// Taxes and fees popover
.checkout-scene .taxes-and-fees-popover {padding: 1rem; font-size: 1rem; color: $gray1;}
.checkout-scene .taxes-and-fees-popover li:first-child {margin-bottom: 1rem !important;}

// Surcharges popover
.MuiPopover-paper .surcharges-popover {padding: 1rem; font-size: 1rem; color: $gray1; line-height: 1.5;}

// Tips popover
.checkout-scene .tips-popover {padding: 1rem; font-size: 0.9rem; color: $gray1; line-height: 1.2;}

.checkout-scene .cta-container {display: flex; justify-content: center; margin-top: 1rem;}

.terms-and-conditions {padding: 0 1rem; text-align: center; color:  $gray3; font-size: 12px; font-weight: 300; line-height: 1.15;}
.terms-and-conditions a {color: $gray2; }
  
@media (max-width: $breakpoint-mobile) {
  // Active Discount
  .checkout-scene .active-discount-expiration {width: 100%; display: flex; align-items: center; justify-content: center; padding: 0 5px; font-size: .8rem; font-weight: 700; height: 45px; background-color: #EDF8FF;}
  .checkout-scene .active-discount-expiration + * {padding-top: 55px;}
  .checkout-scene .active-discount-expiration {position: absolute; left: 0; top: $navbar-height;}
  .checkout-scene .cta-container {font-size: 1rem; position: fixed; bottom: 0; left: 0; width: 100%; height: 50px; border-radius: 0; margin: 0;}

}

// Wallet button
.wallet-button {position: absolute; bottom: 0; left: 0; width: 100%; }

.freedompay-wallet-button iframe {background-color: transparent; overflow: auto; -webkit-overflow-scrolling:touch; min-width: 100%; width: 100px;*width: 100%;  }

@media (min-width: $breakpoint-mobile) {
  // .checkout-scene {max-width: 560px; margin: 0 auto;}
}