// Variables
@import './variables';

// UI
@import './ui/base';
@import './ui/icon';
@import './ui/button';
@import './ui/dialog';
@import './ui/input';
@import './ui/order-status-tag';
@import './ui/spinner';
@import './ui/alerts';
@import './ui/callouts';

// Layout
@import './layout';

// Scenes
@import './scenes/scene';
@import './scenes/location';
@import './scenes/orders';
@import './scenes/events';
@import './scenes/event';
@import './scenes/order-details';
@import './scenes/qr-scanner';
@import './scenes/store-opening-hours';
@import './scenes/checkout';
@import './scenes/checkout-success';
@import './scenes/payment-methods';
@import './scenes/terms-of-use';
@import './scenes/privacy-policy';

// Components
@import './components/sign-in-dialog';
@import './components/product-dialog';
@import './components/order-payment-method';
@import './components/order-cancel-alert';
@import './components/order-status-timeline';
@import './components/qr-scanner';
@import './components/timeframe-row';
@import './components/pickup-details-dialog';
@import './components/age-verification-dialog';
@import './components/select-payment-method-dialog';
@import './components/create-payment-method-dialog';
@import './components/room-charge-details-dialog';
@import './components/payment-method-list';
@import './components/freedompay-form';
@import './components/product-image-dialog';
@import './components/error-alert';
@import './components/location-scene-placeholder';
@import './components/checkout-scene-placeholder';
@import './components/product-dialog-placeholder';

@import './shared';

// Utilities
b {
  font-weight: 600;
}
