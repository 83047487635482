.select-payment-method-dialog {padding: 1rem 1rem 4rem 1rem; display: flex; flex-direction: column; align-items: center; justify-content: center;}

.select-payment-method-dialog .header-icon {margin: 2rem 0;} 
.select-payment-method-dialog .header-icon img {width: 100%;}

.select-payment-method-dialog h5 { margin-bottom: 1rem; width: 100%; text-align: center;}

.select-payment-method-dialog button.room-charge { margin-top: .5rem; width: 100%;}
.select-payment-method-dialog button.add-payment-method { margin-top: .5rem; width: 100%;}

@media (max-width: $breakpoint-mobile) {
  .select-payment-method-dialog .cta {position: fixed; bottom: 0; left: 0; width: 100%; height: 50px; border-radius: 0; margin: 0;}
}