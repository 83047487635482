.sign-in-dialog {padding: 1rem 1rem 4rem 1rem; display: flex; flex-direction: column; align-items: center; justify-content: center;}

.sign-in-dialog header { text-align: center; margin-top: 3rem;}
.sign-in-dialog header .icon {overflow: hidden; margin-bottom: 2rem;}
.sign-in-dialog header h5 {margin-bottom: .5rem; font-size: 1.15rem;}
.sign-in-dialog header {margin-bottom: 2rem; }
.sign-in-dialog header p {color: $gray2; font-size: .875rem; text-align: center;}

.sign-in-dialog .code-sent-message { text-align: center; line-height: 1.5; margin-bottom: .5rem; vertical-align: middle;}
.sign-in-dialog .code-sent-message .phone-number { margin-left: .25rem; cursor: pointer; font-weight: 700; display: inline-flex; color: $light-blue2; align-items: center;}
.sign-in-dialog .code-sent-message .phone-number .icon { margin-left: .25rem;}

.sign-in-dialog .code-not-received-message .action { cursor: pointer; font-weight: 700; display: inline-flex; color: $light-blue2;  margin-left: .25rem;}

.sign-in-dialog .kl-input-group {width: 100%; margin-bottom: 1rem;}
.sign-in-dialog .verification-code-input {margin: 0 auto; }
.sign-in-dialog .verification-code-input + .kl-input-helper {text-align: center; margin-top: .5rem; }
.sign-in-dialog .verification-code-input input {caret-color: $gray4;}
.sign-in-dialog .verification-code-input input:focus {border-color: $light-blue2;}

.sign-in-dialog .sign-in-cta {margin-top: 1rem;}

@media (max-width: $breakpoint-mobile) {
  .sign-in-dialog .sign-in-cta {position: fixed; bottom: 0; left: 0; width: 100%; height: 50px; border-radius: 0; margin: 0;}
}

.grecaptcha-badge { visibility: hidden;}

.recaptcha-attribution-messsage {position: absolute; bottom: 3rem; padding: 1rem; text-align: center; color:  $gray3; font-size: 14px;font-weight: 300;line-height: 1.25; margin-bottom: 1rem; margin-top: .75rem;}
.recaptcha-attribution-messsage a {color: $gray2; }