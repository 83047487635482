.pickup-details-dialog {padding: 1rem 1rem 3rem 1rem;}

.pickup-details-dialog .pickup-details-icon {margin: 2rem 0; text-align: center;} 
.pickup-details-dialog .pickup-details-icon img {width: 100%;}

.pickup-details-dialog h5 { margin-bottom: 1.5rem; width: 100%; text-align: center;}

.pickup-details-dialog .pickup-asap-message { text-align: center;}
.pickup-details-dialog .pickup-asap-message h4 { margin-bottom: .5rem; font-weight: 700;}
.pickup-details-dialog .pickup-asap-message p { font-size: .75rem;}

.pickup-details-dialog .kl-input-group {width: 100%;}
.pickup-details-dialog .pickup-at-form {width: 100%;}
.pickup-details-dialog .pickup-at-form .kl-input-group:last-child { margin-bottom: 0 ;}

.pickup-details-dialog hr {border: none; border-top: 1px solid $light-gray3; width: 100%; margin: 1.5rem 0;}

.pickup-details-dialog .pickup-at-mode-selector { border: 1px solid $light-gray1; width: 100%; display: flex; flex-direction: row; border-radius: .25rem; margin-bottom: 1rem;}
.pickup-details-dialog .pickup-at-mode-selector label {padding: 14px 1rem; display: block; flex: 1; text-align: center; font-weight: 800; color: $gray2}
.pickup-details-dialog .pickup-at-mode-selector label:first-child {border-right: 1px solid $light-gray2; border-bottom-left-radius: .25rem; border-top-left-radius: .25rem; }
.pickup-details-dialog .pickup-at-mode-selector label:last-child {border-bottom-right-radius: .25rem; border-top-right-radius: .25rem; }
.pickup-details-dialog .pickup-at-mode-selector label.checked { font-weight: 800; color: $light-blue2; background-color: white;}
.pickup-details-dialog .pickup-at-mode-selector input {margin: 0;}
.pickup-details-dialog .pickup-at-mode-selector input[type="radio"] { display: none;}