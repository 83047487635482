.scene.scene-orders {padding-top: 0;}
.scene-orders .scene-content {width: 60%; margin: 0 auto;}
.scene-orders .orders-list-title {padding: 1rem; padding-top: 1.5rem; margin: 0; color: $gray3;}
.scene-orders .orders-list > * {margin-bottom: 8px;}
.scene-orders .orders-list {background-color: white; box-shadow: $box-shadow2;}
.scene-orders .orders-list .order-list-item {display: flex; align-items: center; background-color: #FFF; box-shadow: $box-shadow1; border-radius: $border-radius1; padding: .5rem 1rem; cursor: pointer; transform: scale(1); transition: transform 100ms linear;}
.scene-orders .orders-list .order-list-item:hover {transform: scale(1.02);}
.scene-orders .orders-list .order-list-item .store-image {width: 75px; height: 75px; overflow: hidden; border-radius: $border-radius1; margin-right: 12px; flex-shrink: 0;}
.scene-orders .orders-list .order-list-item .store-image img {width: 100%; height: 100%; object-fit: cover; object-position: center;}
.scene-orders .orders-list .order-list-item .order-title .store-name {color: $black1; font-size: 18px; margin-bottom: 4px;}
.scene-orders .orders-list .order-list-item .order-title .order-time {color: $gray3; font-size: 14px;}
.scene-orders .orders-list .order-list-item .order-status {margin-left: auto; margin-right: .5rem; display: flex; flex-direction: column; align-items: flex-end; flex-shrink: 0;}
.scene-orders .orders-list .order-list-item .order-status .order-status-tag {margin-bottom: 4px;}
.scene-orders .orders-list .order-list-item .order-status .order-summary {text-align: right; font-size: 14px;}
.scene-orders .orders-list .order-list-item .order-status .order-summary .items-count {color: $gray3;}
.scene-orders .orders-list .order-list-item .item-icon {color: $gray3; margin-left: 4px; display: none;}

@media (max-width: $breakpoint-mobile) {
  .scene-orders .scene-content {width: 100%; margin: 0; padding-bottom: 2rem; }
  .scene-orders .orders-list .order-list-item {border-radius: 0; box-shadow: none; margin: 0; border-bottom: 1px solid $light-gray4;}
  .scene-orders .orders-list .order-list-item .order-title .store-name {font-size: 16px; margin-bottom: 4px;}
  .scene-orders .orders-list .order-list-item .order-title .order-time {color: $gray3; font-size: 12px;}
  .scene-orders .orders-list .order-list-item .item-icon {display: block;}
}